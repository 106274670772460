import { env } from '../lib/env';

export const SPLIT_CONFIG = {
  core: {
    authorizationKey: env.VITE_SPLIT_CLIENT_API_KEY || '',
  },
};

// This is used if we don't have an account ID to send to Split.
// Split allows you to control which account IDs to serve on/off
// versions of the flags to. If we don't have an account ID, we
// just send this default string and Split will use the default
// treatment. - Carly, Nov. 2022
export const SPLIT_DEFAULT_KEY = 'logged-out';

export enum ACTIVE_SPLITS {
  UNKNOWN = 'unknown',
}
