import axios from 'axios';
import { env } from '../lib/env';

const API_PROTOCOL = env.VITE_APP_GALILEO_API_PROTOCOL ?? 'https';
const API_URL = env.VITE_APP_GALILEO_API_URL;
const API_VERSION = env.VITE_APP_GALILEO_API_VERSION;

export const API_BASE = `${API_PROTOCOL}://${API_URL}/${API_VERSION}`;

export const API = {
  // Account
  account: (accountId: string): string => `${API_BASE}/accounts/${accountId}`,

  accountPhoneVerificationRequest: (accountId: string): string =>
    `${API_BASE}/accounts/${accountId}/mobilePhoneVerification/request`,

  accountPhoneVerificationConfirmation: (accountId: string): string =>
    `${API_BASE}/accounts/${accountId}/mobilePhoneVerification/verify`,

  accountEmployeeSponsorshipVerification: (accountId: string): string =>
    `${API_BASE}/accounts/${accountId}/memberships/accesscode`,

  referenceInsuranceCarriers: (): string =>
    `${API_BASE}/reference/insurance/carriers/inAppEligibility`,

  accountEligibilityClaim: (accountId: string, groupId: string): string =>
    `${API_BASE}/groups/${groupId}/eligibilityClaim/${accountId}`,

  accountCareplanTodos: (accountId: string, careplanId: string): string =>
    `${API_BASE}/accounts/${accountId}/careplans/${careplanId}/todos/patientTodos`,

  accountImagesIdentification: (accountId: string): string =>
    `${API_BASE}/accounts/${accountId}/images/identification`,

  // Appointments:
  appointments: (accountId: string): string =>
    `${API_BASE}/accounts/${accountId}/acuity_appointments`,

  // Quest:
  questRequisition: (accountId: string, questOrderId: string): string =>
    `${API_BASE}/accounts/${accountId}/labs/quest/orders/${questOrderId}/requisition`,

  // SMS:
  sendDownloadLink: (): string => `${API_BASE}/twilio/sendMessage`,
};

export const flaskApiClient = axios.create();
